
.content-stay-connected{
    padding: 0px 150px!important;
    background: #f7f7f7!important;


}

@media (max-width: 1537px) {
    .description-card {
        padding: 0px 4px;

    }
    .content-stay-connected{
        padding: 0px 100px!important;
    }
    .background{
        /* padding: 0px 150px!important; */

    }
  }
  .img-whatsapp{
    margin-top: 80px!important;
  }
  @media (max-width: 1370px) {
    .img-whatsapp{
      margin-top: 60px!important;
    }
     
      .background{
        padding: 0px 20px!important;

    }
    .content-stay-connected{
        padding: 0px 20px!important;
    }
  }

  @media (max-width: 1356px) {
  
      .background{
        padding: 0px 20px!important;

    }
  }
.ready-stay{
    font-size: 50px!important;
    letter-spacing: 0.5%!important;
    line-height: 56px!important;
    color: #25D366!important;
    font-weight: bold!important;
    margin-bottom: 20px!important;
    /* text-transform: uppercase; */
    font-family: "Avenir-bold",sans-serif !important;

}
@media (max-width: 995px) {
  .ready-stay{
    font-size: 21px!important;
    line-height: 30px!important;
    font-family: "Avenir-bold",sans-serif !important;

}
.img-whatsapp{
  margin-top: 60px!important;
}
 .get-started-btn-3{
        width: 100%!important;
      }
      .get-started-btn-4{
        width: 100%!important;
      }
      .get-started-btn-4{}
}
@media (min-width: 767px) and (max-width: 836px) {

  .get-started-btn-3 {
    border-radius: 8px !important;
 width: 30%!important;
  }
  .get-started-btn-4 {
    border-radius: 8px !important;
 width: 100%!important;
  }
  
}
.text-1{
    /* font-size: 26px!important; */
    letter-spacing: 0.5%!important;
    line-height: 34px!important;
    font-weight: bold!important;
}

.get-started-btn-3 {
  border-radius: 8px !important;
  background: black !important;
  text-transform: none !important;
  font-size: 18px !important;
  padding: 8px 24px !important;
  width: 240px;
  margin-top: 30px !important;
  position: static !important;
  font-family: "Avenir" !important;
  font-weight: bold !important;
}

.get-started-btn-3:hover {
  background: #25d366 !important;
}

.get-started-btn-4 {
  border-radius: 8px !important;
  background: black !important;
  text-transform: none !important;
  font-size: 18px !important;
  padding: 8px 24px !important;
  width: 240px!important;
  margin-top: 30px !important;
  position: static !important;
  font-family: "Avenir" !important;
  font-weight: bold !important;
}

.get-started-btn-4:hover {
  background: #25d366 !important;
}
/* @media (max-width:1200px) {
  .box-container{
    padding: 2px 60px!important
  }
} */

@media (max-width: 765px) {
      .get-started-btn-4{
        width: 100%!important;
      }
}