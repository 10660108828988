.background {
}
.content-why-choose {
  padding: 0px 150px !important;
  margin: 20px 0px;
  min-height: 500px !important;
}
.label {
  font-size: 50px !important;
  font-weight: bold !important;
  line-height: 75px !important;
  letter-spacing: 2%;
  font-family: "Avenir-bold",sans-serif !important;

}
.line-green-tall {
  width: 228px !important;
  /* height: 1px !important; */
  border-top: 0.8px solid #58c17d;
  margin: auto 30px;
}

@media (max-width: 1537px) {
  .content-why-choose {
    padding: 0px 100px !important;
    min-height: 500px !important;
  }
}
@media (max-width: 995px) {
  .content-why-choose {
    padding: 0px 10px !important;
  margin: 20px 0px !important;

  }
}

/* PhraseHover.css */

.steps {
  /* display: flex; */
  gap: 10px;
  /* margin-top: 80px; */
}

.step {
  transition: transform 0.8s ease;
  padding: 10px;
  /* cursor: pointer; */
}

.step.hovered {
  transform: scale(1.1);

}
.step.default {
  transform: scale(1.1);
}
.image-container {
  margin-top: -90px;
  z-index: 200;
}

.hover-image {
  /* width: 100%; */
  /* height: auto; */
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.image-container {
  position: relative; /* Ensure positioning for overlay effects */
}

.hover-image {
  position: absolute; /* Stack images on top of each other */
  /* width: 100%; */
  height: auto;
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
  margin-left: 177px;
}

.hover-image-visible {
  opacity: 1;
  z-index: -1;
}
.number-step {
  border-radius: 50%;
  width: 57px;
  height: 57px;
  background: grey;
  color: white;
  font-weight: bold;
  font-size: 33px;
  margin-right: 20px;
  align-items: center !important;
  align-content: center;
}

.number-step.hovered {
  /* Enlarge the image to 120% on hover */
  border-radius: 50%;
  width: 57px;
  height: 57px;
  background: #25d366;
  color: white;
  align-items: center !important;
  align-content: center;
  font-weight: bold;
  font-size: 33px;
  margin-right: 20px;
}

.number-step.not-hovedred {
  background: #000;
}
.number-step.default {
  background: #000;
}
.number-step.default-step1 {
  background: #25d366;
}
.title1 {
  transition: transform 0.3s ease;
  color: grey !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

}
.title1.hovered {
  /* Enlarge the image to 120% on hover */
  color: #25d366 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

}

.title1.default {
  /* Enlarge the image to 120% on hover */
  color: #000 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

}
.title1.default-step1 {
  /* Enlarge the image to 120% on hover */
  color: #25d366 !important;
  font-size: 20px !important;
  font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

}

.title2 {
  transition: transform 0.3s ease;
  color: grey !important;
  font-size: 20px !important;
}

.title2.hovered {
  color: #25d366 !important;
  font-size: 20px !important;
}
.title2.default {
  color: #000 !important;
  font-size: 20px !important;
}

.title2.default-step1 {
  color: #25d366 !important;
  font-size: 20px !important;
}
.list {
  transition: transform 0.3s ease;
  font-size: 14.95px !important;
  color: #b0b0b0 !important;
}

.list.hovered {
  font-size: 14.95px !important;
  color: #000 !important;
}

.list.default-step1 {
    font-size: 14.95px !important;
    color: #000 !important;
  }
  .card-mobile{
    background: #f7f7f7;
    border-radius: 14px;
    padding: 12px;
    margin: auto ;
    width: 100%;
    min-height: 108px;
    
  }

  .number-step-mobile {
    border-radius: 50%;
    width: 41px;
    height: 41px;
    background: grey;
    color: white;
    font-weight: bold;
    font-size: 23px;
    margin-right: 20px;
    align-items: center !important;
    align-content: center;
  }

  .number-step-mobile-green {
    border-radius: 50%;
    width: 41px;
    height: 41px;
    background: #25d366;
    color: white;
    font-weight: bold;
    font-size: 23px;
    margin-right: 20px;
    align-items: center !important;
    align-content: center;
  }

  .title1-mobile {
    color: #000 !important;
    font-size: 14.9px !important;
    font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

  }

  .title1-mobile-green {
    color: #25d366;
    font-size: 14.9px !important;
    font-weight: bold !important;
  font-family: "Avenir-bold",sans-serif !important;

  }

  .title2-mobile {
    color: #000 !important;
    font-size: 14.9px !important;
  }

  .title2-mobile-green {
    color: #25d366;
    font-size: 14.9px !important;
  }


  .list-mobile {
    font-size: 14px !important;
    color: #b0b0b0 !important;
    margin-left: -25px!important;
  }

  .list-mobile-green {
    font-size: 14px !important;
    color: #000 !important;
    margin-left: -25px!important;

  }
  .img-mobile{
    justify-content: center;
    display: flex;
  }

  .line-green-tall-mobile-left {
    width: 50px !important;
    /* height: 1px !important; */
    border-top: 0.8px solid #58c17d;
    margin: auto 0px auto 10px;
  }

  .line-green-tall-mobile-right {
    width: 50px !important;
    /* height: 1px !important; */
    border-top: 0.8px solid #58c17d;
    margin: auto 10px auto 0px;
  }

 
  .label-mobile{
    font-size: 22px!important;
    font-weight: bold!important;
    font-family: "Avenir-bold",sans-serif !important;

  }
  @media (min-width: 767px) and (max-width: 836px) {
    .label-mobile {
      font-size: 28px !important;
    }

    .title1-mobile {
      color: #000 !important;
      font-size: 23px !important;
      font-weight: bold !important;
    font-family: "Avenir-bold",sans-serif !important;
  
    }
  
    .title1-mobile-green {
      color: #25d366;
      font-size: 23px !important;
      font-weight: bold !important;
    font-family: "Avenir-bold",sans-serif !important;
  
    }
  
    .title2-mobile {
      color: #000 !important;
      font-size: 23px !important;
    }
  
    .title2-mobile-green {
      color: #25d366;
      font-size: 23px !important;
    }
  
  
    .list-mobile {
      font-size: 20px !important;
      color: #b0b0b0 !important;
      margin-left: -25px!important;
    }
  
    .list-mobile-green {
      font-size: 20px !important;
      color: #000 !important;
      margin-left: -25px!important;
  
    }


  }
  .card-content{
    width: 97%!important;
    margin: auto -3px auto auto;
    /* display: flex!important;
    justify-content: center!important; */
  }

  @media (max-width: 835px) {
    .hover-image {
      margin-left: 10px;
    }
  }
  .section-title-how-it-works-mobile{
    display: flex;
    justify-Content: center;
    padding:40px;
    background-image: url("../../Assets/Images/WhatsappBackground.png");
    margin-top: 80px;
 
 
 }
 .section-title-how-it-works{
  display: flex;
  justify-Content: center;
  padding:100px;
  background-image: url("../../Assets/Images/WhatsappBackground.png");
  margin-top: 160px;


}

