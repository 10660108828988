.content-faq {
  padding: 0px 150px !important;
  background: white !important;
  z-index: -1;
  margin-top: 120px !important;
  /* width:60% */
}

.section-title-faqs {
  display: flex;
  justify-content: center;
  padding: 0px 45px 90px 45px;
  /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
  margin-top: 0px !important;
  /* background: #25D366!important; */
}


.all-faq{
  width: 60%;
}
.background-still{
  background-image: url("../../Assets/Images/WhatsappBackground.png");
  padding: 50px 150px !important;

}
.faq {
  /* font-size: 36px!important; */
  letter-spacing: -2% !important;
  font-weight: bold !important;
  margin-bottom: 30px !important;
  font-family: "Avenir-bold",sans-serif !important;

}
.faq-question {
  font-size: 18px !important;
  line-height: 28px;
  text-align: justify!important;

}
.faq-question.selected{
  font-weight: bold !important;

}
.faq-response {
  font-size: 15px !important;
  line-height: 20px;
  color: #000 !important;
  margin-left: 43px !important;
  margin-top: 10px!important;
}
.faq-plus-icon {
  color: #4dda83 !important;
  margin-right: 17px !important;
}
.faq-minus-icon {
  color: #98a2b3 !important;
  margin-right: 17px !important;
}
.content-question {
  /* display: flex!important; */
  cursor: pointer !important;
  padding: 16px 16px !important;
  margin-bottom: 10px;
  background: #FCFCFD;
  border-radius: 16px;

}

.still-have-question {
  font-size: 28px !important;
  font-weight: bold !important;
  color: #25d366 !important;
  font-family: "Avenir-bold",sans-serif !important;

}

.cant-find {
  font-size: 18px !important;
  font-weight: 500 !important;
  line-height: 28px !important;
}

@media (max-width: 1537px) {
  .content-faq {
    padding: 0px 100px !important;
  }
  .background-still{
    padding: 50px 100px !important;
  
  }
}

@media (max-width: 995px) {
  .content-faq {
    padding: 0px 20px !important;
    margin-top: 80px !important;
    width: 100%!important;
  }
  .background-still{
    padding: 50px 20px !important;
  
  }
  .all-faq{
    width: 100%;
  }
}

.faq-response {
  max-height: 0;
  opacity: 0;
  overflow: hidden;
  transition: max-height 0.5s ease, opacity 0.5s ease;
}

.faq-response.show {
  max-height: 500px; /* or auto if the height is not predictable */
  opacity: 1;
}

@media (max-width: 995px) {
  .still-have-question {
    font-size: 23px !important;
    font-family: "Avenir-bold",sans-serif !important;

  }
  .faq-question {
    font-size: 16px !important;
    /* display: flex!important; */
    cursor: pointer !important;
    margin-bottom: 0px !important;
  text-align: justify!important;

  }
  .content-question{
    padding: 5px 0px!important;
  }
  .faq-response {
    font-size: 15px !important;
    text-align: justify!important;
  }
}

@media (max-width: 765px) {
  .section-title-faqs {
    display: flex;
    justify-content: center;
    padding: 15px;
    /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
    margin-top: 20px !important;
  }
  .label-second {
font-size: 18px!important;
}
}


@media (min-width: 767px) and (max-width: 836px) {
  .section-title-faqs {
    display: flex;
    justify-content: center;
    padding: 50px 45px 10px 45px;
    /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
    margin-top: 15px !important;
  }

}

@media (min-width: 836px) and (max-width: 1025px) {
  .all-faq{
    width: 80%;
  }
  .label-second{
text-align: center;
  }
}