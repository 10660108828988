.background {
  min-height: 550px;
  /* background-image: url("../../Assets/Images//WhatsappBackground.png"); */
}

/* .section-title {
  display: flex;
  justify-content: center;
  padding: 45px;
  background-image: url("../../Assets/Images/WhatsappBackground.png");
  margin-top: 150px !important;

} */

.section-title-why-choose {
  display: flex;
  justify-content: center;
  padding: 80px 45px 10px 45px;
  /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
  margin-top: 150px !important;
  /* background: #25D366!important; */
}

.content {
  padding: 0px 150px 30px 150px !important;
  /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
  background: #25d366 !important;
}

.label-second {
  font-size: 50px !important;
  font-weight: bold !important;
  line-height: 60px !important;
  letter-spacing: 2%;
  font-family: "Avenir-bold", sans-serif !important;
  margin-bottom: 40px!important;
}

@media (max-width: 765px) {
  .label-second {
    font-size: 20px !important;
    font-weight: bold !important;
    line-height: 75px !important;
    letter-spacing: 2%;
    font-family: "Avenir-bold", sans-serif !important;
    margin-bottom: 0px!important
  }

  .section-title-why-choose {
    display: flex;
    justify-content: center;
    padding: 15px;
    /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
    margin-top: 20px !important;
  }

  .background {
    padding: 0px;
  }
}

.line-green {
  width: 126px !important;
  /* height: 1px!important; */
  border-top: 0.8px solid black;
  border-bottom: none;
  margin: auto 30px;
}

.line-green-mobile {
  width: 25px !important;
  height: 1px !important;
  border-top: 0.8px solid black;
  border-bottom: none;

  margin: auto 10px;
}

.title-card {
  font-size: 22px !important;
  font-family: "Avenir-bold", sans-serif !important;

  color: #25d366 !important;
  font-weight: bold !important;
}

.description-card {
  font-size: 16px !important;
  color: #000 !important;
  line-height: 26px !important;
  padding: 0px 74px;
}

@media (max-width: 1537px) {
  .description-card {
    padding: 0px 4px;
  }

  .content {
    padding: 0px 100px 30px 100px !important;
  }

  .background {
    /* padding: 0px 150px!important; */
  }
}

.card {
  padding: 30px 10px !important;
  border-radius: 24px !important;
  background: white;
}

@media (max-width: 765px) {
  .background {
    padding: 0px 20px !important;
  }

  .content {
    padding: 0px 0px 30px 0px !important;
  }

  .title-card {
    font-size: 18px !important;
  }

  .description-card {
    font-size: 14px !important;
    padding: 0px 80px;
  }

  .card {
    padding: 15px 7px !important;
  }
}

@media (max-width: 1356px) {
  .background {
    padding: 0px 20px !important;
  }

  .description-card {
    padding: 0px 10px;
  }
}

/* .ready-stay{
    font-size: 50px!important;
    letter-spacing: 0.5%!important;
    line-height: 56px!important;
    color: #25D366!important;
    font-weight: bold!important;
    margin-bottom: 20px!important;
} */
/* .text-1{
    font-size: 26px!important;
    letter-spacing: 0.5%!important;
    line-height: 34px!important;
    font-weight: bold!important;
} */

@media (min-width: 767px) and (max-width: 836px) {
  .label-second {
    font-size: 28px !important;
    font-weight: bold !important;
    line-height: 75px !important;
    letter-spacing: 2%;
    font-family: "Avenir-bold", sans-serif !important;
  }

  .line-green-mobile {
    width: 25px !important;
    height: 1px !important;
    border-top: 0.8px solid black;
    border-bottom: none;

    margin: auto 10px;
  }

  .line-green {
    width: 50px !important;
    /* height: 1px!important; */
    border-bottom: 1px solid #000;
    margin: auto 30px;
  }

  .content {
    padding: 0px 30px 30px 30px !important;
  }

  .section-title-why-choose {
    display: flex;
    justify-content: center;
    padding: 50px 45px 10px 45px;
    /* background-image: url("../../Assets/Images/WhatsappBackground.png"); */
    margin-top: 15px !important;
  }
}