@media (max-width: 767px) {
  .box-container {
    padding: 2px 180px !important;
    /* z-index: -1; */
  }
  .container-get-started {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .container-get-started {
    margin-top: 50px;
  }
}
@media (min-width: 1537px) {
  .box-container {
    padding: 2px 180px !important;
  }
}
@media (min-width: 767px) {
  .travel-esim {
    font-size: 33px !important ;
    /* font-weight: 700!important; */
    line-height: 63px !important;
    letter-spacing: 0.5% !important;
    color: black !important                    ;
  }
}
.mini-line {
  border-top: 0.8px solid #25d366;
  border-bottom: none;
  width: 32px;
  margin: auto 12px auto 0px;
  height: 1px;
  align-content: center;
}

@media (max-width: 767px) {
  .travel-esim {
    font-size: 18px !important ;
    /* font-weight: 700!important; */
    /* line-height: 63px !important; */
    letter-spacing: 0.5% !important;
    color: black !important                    ;
  }
  .mini-line {
    border-top: 0.8px solid #25d366;
    border-bottom: none;    width: 17px;
    margin: 0px 12px 0px 0px;
    height: 1px;
    align-items: center;
    align-content: center;
  }
}
@media (min-width: 767px) {
  .stay-connected {
    font-size: 18px !important ;
    /* font-weight: 700!important; */
    line-height: 26px !important;
    letter-spacing: 0.5% !important;
    color: #25d366 !important;
  }
}

@media (max-width: 767px) {
  .stay-connected {
    font-size: 16px !important ;
    /* font-weight: 700!important; */
    /* line-height: 26px !important; */
    letter-spacing: 0.5% !important;
    color: #25d366 !important;
    text-align: left;
    align-content: center;
  }
}

@media (min-width: 767px) {
  .big-title {
    font-family: "Avenir-bold", sans-serif !important;

    font-size: 38px !important ;
    font-weight: 700 !important;
    line-height: 63px !important;
    letter-spacing: 0.5% !important;
    color: black !important;
  }
}

@media (max-width: 767px) {
  .big-title {
    font-size: 22px !important ;
    font-family: "Avenir-bold", sans-serif !important;
    /* line-height: 63px !important; */
    letter-spacing: 0.5% !important;
    color: black !important;
  }
}

@media (min-width: 767px) {
  .big-title-green {
    font-size: 60px !important ;
    font-weight: 700 !important;
    line-height: 63px !important;
    letter-spacing: 0.5% !important;
    color: #25d366 !important;
    font-family: "Avenir-bold", sans-serif !important;
  }
}

@media (max-width: 767px) {
  .big-title-green {
    font-size: 22px !important ;
    font-weight: 700 !important;
    /* line-height: 63px !important; */
    letter-spacing: 0.5% !important;
    color: #25d366 !important;
    font-family: "Avenir-bold", sans-serif !important;
  }
}
@media (min-width: 767px) {
  .box-container {
    padding: 2px 180px !important;
  }
}
@media (min-width: 767px) {
  .box-container {
    padding: 2px 180px !important;
  }
  .small-doc {
    font-size: 16px !important ;
    line-height: 20px !important;
    letter-spacing: 0.5% !important;
    color: #000 !important;
    margin-top: 30px !important;
    max-width: 600px !important;
    text-align: justify !important;
  }
}
@media (max-width: 1200px) {
  .box-container {
    padding: 2px 60px !important;
  }
}

@media (max-width: 767px) {
  .small-doc {
    font-size: 16px !important ;
    line-height: 20px !important;
    letter-spacing: 0.5% !important;
    color: #7d7c84 !important;
    margin-top: 30px !important;
    max-width: 600px !important;
    text-align: justify !important;
  }
}

.container {
  /* position: relative;
    width: 1000px;
    height: 1000px;
    overflow: hidden; */
  /* position: relative; */
}

.bigimg {
  position: absolute;
  width: 100%; /* Set the initial width of the image */
  transition: transform 1s ease; /* Smooth transition for the scaling effect */
  z-index: -2;
}

.container:hover .bigimg {
  position: absolute;

  transform: scale(1.02); /* Enlarge the image to 120% on hover */
  z-index: -2;
}

.car {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: -30px;
  left: 40%;
  transform-origin: -75px;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}

@media (max-width: 1537px) {
  .car {
    margin-top: -30px;
    left: 60%;
  }
}

.container:hover .car {
  transform: translateX(20px) translateY(20px);
}
.man {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  top: 15%;
  left: 72%;
  transform-origin: -75px;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}

@media (max-width: 1537px) {
  .man {
    top: 15%;
    left: 100%;
  }
}

.container:hover .man {
  transform: translateX(10px) translateY(50px);
}
.mountain {
  position: absolute;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-top: 45%;
  left: 55%;
  transform-origin: -75px;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}
@media (max-width: 1537px) {
  .mountain {
    margin-top: 60%;
    left: 75%;
  }
}

.container:hover .mountain {
  transform: translateX(-20px) translateY(30px);
}

.whatsapp {
  position: absolute;
  width: 50px;
  height: 50px;
  margin-top: 100px;
  left: 5%;
  transform-origin: -75px;
  transition: transform 0.5s ease-in-out;
  z-index: -1;
}
@media (max-width: 1537px) {
  .whatsapp {
    margin-top: 110px;
    left: 5%;
  }
}

.container:hover .whatsapp {
  transform: translateX(20px) translateY(-50px);
}

.get-started-btn-2 {
  position: relative !important;
  border-radius: 8px !important;
  background: black !important;
  text-transform: none !important;
  font-size: 18px !important;
  padding: 8px 24px 8px 24px !important;
  width: 240px;
  /* z-index: -1; */
  font-family: "Avenir" !important;
  font-weight: bold !important;
  cursor: pointer !important;
}
.container:hover .get-started-btn-2 {
  background: #25d366 !important;
  font-family: "Avenir" !important;
  font-weight: bold !important;
  cursor: pointer !important;
}
.left-side {
  transition: transform 0.5s ease-in-out;
}
.container:hover .left-side {
  transform: translateY(-10px);
}

.img-tablet-container {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin-bottom: -140px;
  margin-top: -107px;
}

.img-mobile-container {
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  margin-bottom: -70px;
}
.tablet-img-container {
  align-content: center !important;
}
@media (max-width: 767px) {
  .box-container {
    padding: 2px 5px !important;
  }
}

@media (max-width: 765px) {
  .get-started-btn-2 {
    width: 100% !important;
  }
}

@media (min-width: 767px) and (max-width: 836px) {
  .get-started-btn-2 {
    width: 30% !important;
  }
  
}
