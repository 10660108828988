.MuiToolbar-root {
  width: auto!important;
  max-width: none!important; /* 'none' allows the element to take full width */
}
.on-the-top{
  z-index: 22!important;
}
/* Header Title Styles */
.header-title {
  font-weight: bold!important;
}

/* Larger screens */
@media (min-width: 1500px) {
  .header-title {
      font-size: 20px!important;
  }
  .get-started-btn {
      border-radius: 8px!important;
      background: black!important;
      text-transform: none!important;
      font-size: 18px!important;
      padding: 3px 40px!important;
  }
  .header-container {
      padding:0px 180px 0px 180px!important;
      padding-top: 10px!important;
      padding-bottom: 5px!important;
  }
}


@media (max-width: 1597px) {

  .header-container {
      padding: 0px 60px 0px 80px!important;
      padding-top: 10px!important;
      padding-bottom: 5px!important;

  }
}


/* Smaller screens */
@media (max-width: 954px) {
  .header-title {
      font-size: 12px!important;
      margin-right: 0px!important;
  }
  .get-started-btn {
      border-radius: 8px!important;
      background: black!important;
      text-transform: none!important;
      font-size: 11px!important;
      padding: 10px 14px!important;
      font-family: "Avenir";
      margin-top: 6px!important;
      /* margin-left:-44px!important; */
  }
  .header-container {
      padding: 0px 10px!important;
      padding-top: 10px!important;
      padding-bottom: 5px!important;
      width: 100%!important;

  }
}

/* General Get Started Button Style */
.get-started-btn {
  background: black!important;
  text-transform: none!important;
  font-family: "Avenir"!important;
  font-weight: bold!important;
  border-radius: 8px!important;
}

@media (max-width: 429px) {
  .get-started-btn {
      border-radius: 8px!important;
      background: black!important;
      text-transform: none!important;
      font-size: 11px!important;
      padding: 10px 14px!important;
      font-family: "Avenir";
      margin-top:3px!important;
      /* margin-left:-44px!important; */
  }
}