@font-face {
  font-family: 'Avenir';
  src: url('./Assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff') format('woff'),
       url('./Assets/fonts/AvenirNextCyr/AvenirNextCyr-Regular.woff2') format('woff2');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Avenir-bold';
  src: url('./Assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff') format('woff'),
       url('./Assets/fonts/AvenirNextCyr/AvenirNextCyr-Bold.woff2') format('woff2');
  font-weight: bold;
  font-style: normal;
}

body {
  margin: 0;
  font-family: "Avenir",sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: "Avenir",sans-serif !important;
}
.MuiTypography-root{
  font-family: "Avenir",sans-serif !important;

}
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 3px grey; 
  border-radius: 8px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(107, 107, 107); 
}