.container-footer {
  padding: 40px 150px !important;
  width: 100% !important;
}
.green-title {
  color: #25d366 !important;
  font-size: 16px !important;
  margin-bottom: 5px !important;
}
.white-label {
  color: #fff !important;
  font-size: 18px !important;
  margin: 5px 0px !important;
  letter-spacing: -2% !important;
  cursor: pointer !important;
}
.white-label:hover {
  color: #25d366 !important;
}
.bold-label {
  font-size: 22px !important;
  font-weight: bold !important;
  margin-bottom: 10px;
  cursor: pointer !important;
}
.bold-label:hover {
  color: #25d366 !important;

}
.copyright {
  color: #000 !important;
  font-size: 14px !important;
  background: #25d366 !important;
  text-align: right !important;
  padding-right: 140px !important;
  font-weight: bold !important;
  height: 40px !important;
  align-content: center !important;
}

.copyright-mobile {
  color: #000 !important;
  font-size: 14px !important;
  background: #25d366 !important;
  text-align: left !important;
  padding-left: 20px !important;
  font-weight: bold !important;
  height: 35px!important;
  align-content: center!important;
}

@media (max-width: 1537px) {
  .container-footer {
    padding: 40px 100px !important;
  }
  .copyright {
    padding-right: 90px !important;
  }
}

@media (max-width: 995px) {
  .container-footer {
    padding: 40px 20px !important;
  }
}
